/* eslint-disable sonarjs/no-identical-functions */
import moment from 'moment';
import * as Yup from 'yup';
// const epochTimeStamp = Math.floor(new Date().getTime() / 1000.0);

export const getTimeSeconds = () => {
	const timeStamp = moment().format('YYYY/MMM/DD/hhA').toLowerCase();
	const timeSeconds = moment().format('HHmmss');
	return { timeStamp, timeSeconds };
};
// export const GetEnvironmentString = (data) => {
// 	switch (data) {
// 		case 'development':
// 			return 'dev';
// 		case 'staging':
// 			return 'dev';
// 		case 'training':
// 			return 'training';
// 		case 'preprod':
// 			return 'training';
// 		case 'production':
// 			return 'prod-v1';
// 		case 'testing':
// 			return 'test';
// 		default:
// 			return 'dev';
// 	}
// };

const getBaseEnv = process.env?.REACT_APP_ENV_UPLOAD_PATH || 'dev';
const getSignedUploadEnv = process.env?.REACT_APP_UPLOAD_SIGNED_ENV || 'dev';

export const removeSpecialCharac = (nextfile) => {
	const removeExnt = nextfile.name.replace('.csv', '');
	const removedSpecialCharac = removeExnt.replace(/[^A-Z0-9_]/gi, '');
	return `${removedSpecialCharac}.csv`;
};

export const getUploadfilePath = (
	nextfile,
	file,
	allocation_id,
	grn = false,
	salesReturnUpload = false
) => {
	const { timeSeconds } = getTimeSeconds();
	const index = nextfile.name.lastIndexOf('.');
	const removeExnt = nextfile.name.substring(0, index + 1).trim();
	const removedSpecialCharac = removeExnt.replace(/[^A-Z0-9_]/gi, '');
	let filename = `${removedSpecialCharac}.${nextfile.type.split('/')[1]}`;
	filename = `${timeSeconds}_${filename}`;
	let s3key = '';
	if (grn) {
		s3key = 'grn';
	} else if (salesReturnUpload) {
		s3key = 'creditnoteinv';
	}
	const filenamePath =
		grn || salesReturnUpload
			? `${getSignedUploadEnv}/epod/${s3key}/${filename}`
			: `${getSignedUploadEnv}/epod/${allocation_id}/${filename}`;
	console.log(filenamePath, filename, 'filenamePath');
	return { filename, filenamePath };
};

export const getUploadfilePathEpodTwo = (nextfile) => {
	const { timeSeconds } = getTimeSeconds();
	const index = nextfile.name.lastIndexOf('.');
	const removeExnt = nextfile.name.substring(0, index + 1).trim();
	const removedSpecialCharac = removeExnt.replace(/[^A-Z0-9_]/gi, '');
	let filename = `${removedSpecialCharac}.${nextfile.type.split('/')[1]}`;
	filename = `${timeSeconds}_${filename}`;
	const filenamePath = `invoice_copies/${filename}`;
	// const filenamePath = `${getSignedUploadEnv}/invoice_copies/${filename}`;
	console.log(filenamePath, filename, 'filenamePath');
	return { filename, filenamePath };
};

export const removeSpl = (data) => `${data.replace('.csv', '').replace(/[^A-Z0-9_/]/gi, '')}.csv`;

export const getFilePath = (fc_value, brand_value, name, path) => {
	const { timeStamp, timeSeconds } = getTimeSeconds();
	console.log('path below  ', path);
	console.log('name below  ', name);

	const filename = removeSpl(name[0]);
	console.log('filename  - ', filename);

	if (path === 'sales_return') {
		return `${getBaseEnv}/multifile/salesorder/${path}/${fc_value}/${brand_value}/${timeStamp}/${timeSeconds}_${filename}`;
	}
	return `${getBaseEnv}/multifile/${path}/${fc_value}/${brand_value}/${timeStamp}/${timeSeconds}_${filename}`;
};

export const getMetafilePath = (nextfile, file, values, path) => {
	const { timeStamp, timeSeconds } = getTimeSeconds();
	console.log(timeStamp, values);
	const { fc: fc_value, brand: brand_value } = values;
	const { excel = [] } = values;
	const baseEnvironment =
		file?.length > 1 || file !== excel ? `${getBaseEnv}/multifile` : getBaseEnv;
	const filename = removeSpecialCharac(nextfile);
	if (path === 'packmaster') {
		return `${baseEnvironment}/salesorder/pack_master/${brand_value}/${timeStamp}/${timeSeconds}_${filename}`;
	}
	if (path === 'sales_return') {
		return `${baseEnvironment}/salesorder/${path}/${fc_value}/${brand_value}/${timeStamp}/${timeSeconds}_${filename}`;
	}
	return `${baseEnvironment}/${path}/${fc_value}/${brand_value}/${timeStamp}/${timeSeconds}_${filename}`;
};

// to Generate HUL Multifille Obeject using file path
export const getHULMultiFileObject = (
	fc_value,
	brand_value,
	sales_register,
	shogun,
	bill,
	path
) => {
	let mutifileObj = {};
	const getSaleFile = getFilePath(
		fc_value,
		brand_value,
		sales_register?.map((a) => a.name),
		path
	);
	const getShogun = getFilePath(
		fc_value,
		brand_value,
		shogun?.map((a) => a.name),
		path
	);
	const getbill = getFilePath(
		fc_value,
		brand_value,
		bill?.map((a) => a.name),
		path
	);

	mutifileObj = {
		sales_register: getSaleFile,
		shogun: getShogun,
		bill: getbill,
	};
	return mutifileObj;
};

// to Generate ITC Multifille Obeject using file path
export const getITCFieObject = (fc_value, brand_value, sales, master, credit_adj, path) => {
	let mutifileObj = {};
	const getSales = getFilePath(
		fc_value,
		brand_value,
		sales?.map((a) => a.name),
		path
	);

	const getMaster = getFilePath(
		fc_value,
		brand_value,
		master?.map((a) => a.name),
		path
	);

	const getCreditAdj = getFilePath(
		fc_value,
		brand_value,
		credit_adj?.map((a) => a.name),
		path
	);

	mutifileObj = {
		sales: getSales,
		master: getMaster,
		credit_adj: getCreditAdj,
	};
	return mutifileObj;
};
// to generate BRIT multifile object using file path
export const getBRITFieObject = (fc_value, brand_value, so_report, inv_report, register, path) => {
	let mutifileObj = {};
	const getSales = getFilePath(
		fc_value,
		brand_value,
		so_report?.map((a) => a.name),
		path
	);

	const getInv = getFilePath(
		fc_value,
		brand_value,
		inv_report?.map((a) => a.name),
		path
	);

	const getReg = getFilePath(
		fc_value,
		brand_value,
		register?.map((a) => a.name),
		path
	);
	mutifileObj = {
		sales: getSales,
		invoice: getInv,
		register: getReg,
	};
	return mutifileObj;
};

export const getNvaDbrFileObject = (fc_value, brand_value, so_report, inv_report, path) => {
	let mutifileObj = {};
	const getSales = getFilePath(
		fc_value,
		brand_value,
		so_report?.map((a) => a.name),
		path
	);

	const getInv = getFilePath(
		fc_value,
		brand_value,
		inv_report?.map((a) => a.name),
		path
	);
	mutifileObj = {
		sales: getSales,
		invoice: getInv,
	};
	return mutifileObj;
};

export const getNeslFileObject = (fc_value, brand_value, sales, billwise, credit_adj, path) => {
	let mutifileObj = {};

	const getSales = getFilePath(
		fc_value,
		brand_value,
		sales?.map((a) => a.name),
		path
	);

	const getBillwise = getFilePath(
		fc_value,
		brand_value,
		billwise?.map((a) => a.name),
		path
	);

	const getCreditAdj = getFilePath(
		fc_value,
		brand_value,
		credit_adj?.map((a) => a.name),
		path
	);

	mutifileObj = {
		sales: getSales,
		billwise: getBillwise,
		credit_adj: getCreditAdj,
	};

	return mutifileObj;
};

export const getGDJFileObject = (fc_value, brand_value, sales, credit_adj, path) => {
	let mutifileObj = {};

	const getSales = getFilePath(
		fc_value,
		brand_value,
		sales?.map((a) => a.name),
		path
	);

	const getcreditAdj = getFilePath(
		fc_value,
		brand_value,
		credit_adj?.map((a) => a.name),
		path
	);
	mutifileObj = {
		sales: getSales,
		credit_adj: getcreditAdj,
	};
	return mutifileObj;
};

export const getHULSalesReturnFileObject = (fc_value, brand_value, master, gstr, path) => {
	let mutifileObj = {};

	const getMaster = getFilePath(
		fc_value,
		brand_value,
		master?.map((a) => a.name),
		path
	);

	const getGSTR = getFilePath(
		fc_value,
		brand_value,
		gstr?.map((a) => a.name),
		path
	);
	mutifileObj = {
		master: getMaster,
		gstr: getGSTR,
	};
	return mutifileObj;
};

export const AdpterFormSchema = Yup.object({
	upload_type: Yup.string(),
	fc: Yup.mixed()
		.label('FC(s)')
		.when(['upload_type'], {
			is: (upload_type) => upload_type?.includes('picklite_order_adapter' || 'picklite_grn'),
			then: Yup.mixed().required(),
		}),
	brand: Yup.string().required().label('Brand(s)'),
	excel: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			((!brand?.includes('ITC') &&
				!brand?.includes('HUL') &&
				!brand?.includes('BRIT') &&
				!brand?.includes('NVA') &&
				!brand?.includes('DBR') &&
				!brand?.includes('NESL') &&
				!brand?.includes('GDJ') &&
				upload_type?.includes('picklite_order_adapter')) ||
				upload_type?.includes('pack_master') ||
				upload_type?.includes('picklite_grn')) &&
			brand,
		then: Yup.mixed().required(),
	}),
	sales: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			(brand?.includes('ITC') || brand?.includes('NESL') || brand?.includes('GDJ')) &&
			upload_type?.includes('picklite_order_adapter') &&
			brand,
		then: Yup.mixed().required(),
	}),
	billwise: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('NESL') && upload_type?.includes('picklite_order_adapter') && brand,
		then: Yup.mixed().required(),
	}),
	master: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			((brand?.includes('ITC') && upload_type?.includes('picklite_order_adapter')) ||
				(brand?.includes('HUL') && upload_type?.includes('sales_return'))) &&
			brand,
		then: Yup.mixed().required(),
	}),
	so_report: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			(brand?.includes('BRIT') || brand?.includes('NVA') || brand?.includes('DBR')) &&
			upload_type?.includes('picklite_order_adapter') &&
			brand,
		then: Yup.mixed().required(),
	}),
	inv_report: Yup.mixed().when(['brand', 'upload_type'], {
		// eslint-disable-next-line sonarjs/no-identical-functions
		is: (brand, upload_type) =>
			(brand?.includes('BRIT') || brand?.includes('NVA') || brand?.includes('DBR')) &&
			upload_type?.includes('picklite_order_adapter') &&
			brand,
		then: Yup.mixed().required(),
	}),

	register: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('BRIT') && upload_type?.includes('picklite_order_adapter') && brand,
		then: Yup.mixed().required(),
	}),

	sales_register: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('HUL') && upload_type?.includes('picklite_order_adapter') && brand,
		then: Yup.mixed().required(),
	}),
	shogun: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('HUL') && upload_type?.includes('picklite_order_adapter') && brand,
		then: Yup.mixed().required(),
	}),
	bill: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('HUL') && upload_type?.includes('picklite_order_adapter') && brand,
		then: Yup.mixed().required(),
	}),
	gstr: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			brand?.includes('HUL') && upload_type?.includes('sales_return') && brand,
		then: Yup.mixed().required(),
	}),
	credit_adj: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			(brand?.includes('NESL') || brand?.includes('GDJ') || brand?.includes('ITC')) &&
			upload_type?.includes('picklite_order_adapter') &&
			brand,
		then: Yup.mixed().required(),
	}),
	sales_return_upload: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			(brand?.includes('NVA') || brand?.includes('LOREAL')) &&
			upload_type?.includes('sales_return') &&
			brand,
		then: Yup.mixed().required(),
	}),
	sales_return_product: Yup.mixed().when(['brand', 'upload_type'], {
		is: (brand, upload_type) =>
			(brand?.includes('NVA') || brand?.includes('LOREAL')) &&
			upload_type?.includes('sales_return') &&
			brand,
		then: Yup.mixed().required(),
	}),
});

export const formInitialValues = (uploadType, auth, isAdmin, me) => ({
	upload_type: uploadType,
	fc: isAdmin ? undefined : auth?.user?.FCs?.[0]?.code,
	user_id: me?.id.toString(),
	brand: isAdmin ? undefined : auth?.user?.Brands?.[0]?.code,
	non_picking: false,
	excel: undefined,
	sales_register: undefined,
	shogun: undefined,
	bill: undefined,
	sales: undefined,
	master: undefined,
	so_report: undefined,
	inv_report: undefined,
	pickers: undefined,
	register: undefined,
	billwise: undefined,
	credit_adj: undefined,
	gstr: undefined,
	brandsOptions: [],
	sales_return_upload: undefined,
	sales_return_product: undefined,
});

export const OrderAdapterUploadList = {
	HUL: [
		{
			label: 'Bill',
			name: 'bill',
		},
		{
			label: 'Sales Register',
			name: 'sales_register',
		},
		{
			label: 'Shogun',
			name: 'shogun',
		},
	],
	ITC: [
		{
			label: 'Item Master',
			name: 'master',
		},
		{
			label: 'Sales',
			name: 'sales',
		},
		{
			label: 'ITC Itemwise sales returns',
			name: 'credit_adj',
		},
	],
	NESL: [
		{
			label: 'Sales Order',
			name: 'sales',
		},
		{
			label: 'Billwise Productwise Sales',
			name: 'billwise',
		},
		{
			label: 'Sales Report Bill Wise',
			name: 'credit_adj',
		},
	],
	BRIT: [
		{
			// eslint-disable-next-line sonarjs/no-duplicate-string
			label: 'Sales Order Report (SO Report)',
			name: 'so_report',
		},
		{
			label: 'Invoice Report',
			name: 'inv_report',
		},
		{
			label: 'Sales Register',
			name: 'register',
		},
	],
	NVA: [
		{
			label: 'Sales Order Report (SO Report)',
			name: 'so_report',
		},
		{
			label: 'Billwise Retailer sales',
			name: 'inv_report',
		},
	],
	DBR: [
		{
			label: 'Sales Order Report (SO Report)',
			name: 'so_report',
		},
		{
			label: 'Sales value Report Billwise',
			name: 'inv_report',
		},
	],
	GDJ: [
		{
			label: 'Sales Order Report (SO Report)',
			name: 'sales',
		},
		{
			label: 'Credit Adjustment',
			name: 'credit_adj',
		},
	],
};

export const SalesReturnUploadList = {
	HUL: [
		{
			label: 'SalesReturnDetails',
			name: 'master',
		},
		{
			label: 'GST Return',
			name: 'gstr',
		},
	],
	NVA: [
		{
			label: 'Sales Return',
			name: 'sales_return_upload',
		},
		{
			label: 'Sales Return Product',
			name: 'sales_return_product',
		},
	],
	LOREAL: [
		{
			label: 'Sales Return',
			name: 'sales_return_upload',
		},
		{
			label: 'Sales Return Product',
			name: 'sales_return_product',
		},
	],
};
